const defaults = require('./defaults');

module.exports = {
  ...defaults,
  hot: false,
  deploy: {
    domain: 'development.email.localsearch.cloud',
    zoneID: 'Z08893292663EZJ0GD02V',
    certificateArn:
      'arn:aws:acm:us-east-1:789417954668:certificate/13dbf2fa-c61e-4232-8979-c53285b3a5a3',
  },
  subscriptions: {
    baseUrl:
      'https://w6eykzwt2b.execute-api.ap-southeast-2.amazonaws.com/development',
  },
};
