import loader from './loader.json';
import splash from './splash.json';
import envelope from './envelope.json';
import loadingDots from './loadingDots.json';
import loading2 from './loading2.json';
import loading3 from './loading3.json';
import empty1 from './empty1.json';
import empty2 from './empty2.json';

export {
  loader,
  splash,
  envelope,
  loadingDots,
  loading2,
  loading3,
  empty1,
  empty2,
};
